<template>
  <router-view />
</template>

<style lang="scss">
@import "reset-css";
@import "./assets/stylesheets/common.scss";
@import "./assets/stylesheets/contact.scss";
@import "./assets/stylesheets/point_history.scss";
.tori {
  @import "./assets/stylesheets/tori/common.scss";
  @import "./assets/stylesheets/tori/main.scss";
  @import "./assets/stylesheets/tori/colors.scss";
}
.balloon {
  @import "./assets/stylesheets/balloon/common.scss";
  @import "./assets/stylesheets/balloon/main.scss";
  @import "./assets/stylesheets/balloon/colors.scss";
}
.rakuten {
  @import "./assets/stylesheets/rakuten/common.scss";
  @import "./assets/stylesheets/rakuten/main.scss";
  @import "./assets/stylesheets/rakuten/colors.scss";
}
</style>
