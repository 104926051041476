<template>
  <!-- ヘッダー -->
  <header id="game-header">
    <div id="game-header__inner">
      <div class="game-header__btn">
        <a href="https://pointmall.rakuten.co.jp/game">ゲームトップ</a>
      </div>
      <div class="game-header__logo">
        <a href="https://pointmall.rakuten.co.jp">
          <img
            src="../../assets/images/rakuten/header/pc/logo.png"
            alt="Rakuten Point Mall"
            class="pc"
          />
          <img
            src="../../assets/images/rakuten/header/sp/logo.png"
            alt="Rakuten Point Mall"
            class="sp"
          />
        </a>
      </div>
    </div>
  </header>
  <!-- END ヘッダー -->

  <!-- パンくず -->
  <div class="breadcrumb-wrap">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a href="https://pointmall.rakuten.co.jp">トップ</a>
      </li>
      <li class="breadcrumb-item">
        <a href="https://pointmall.rakuten.co.jp/game">ゲームトップ</a>
      </li>
      <li class="breadcrumb-item">
        <a href="https://pointmall.rakuten.co.jp/game/shinden"
          >進め！幻の海底神殿トップ</a
        >
      </li>
      <li class="breadcrumb-item active">進め！幻の海底神殿プレイ</li>
    </ol>
  </div>
  <!-- END パンくず -->
</template>

<script>
export default {
  name: "RakutenHeader",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  watch: {},
  mounted: function () {
    // nothing
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 415px) {
  @import "~@/assets/stylesheets/rakuten/header/pc/games.scss";
  @import "~@/assets/stylesheets/rakuten/header/pc/rp-common.scss";
  .pc {
    display: inline;
  }
  .sp {
    display: none;
  }
  .game-header__btn {
    a {
      line-height: initial;
      color: #be0000;
    }
  }
}

@media screen and (max-width: 414px) {
  @import "~@/assets/stylesheets/rakuten/header/sp/games.scss";
  @import "~@/assets/stylesheets/rakuten/header/sp/rp-common.scss";
  .pc {
    display: none;
  }
  .sp {
    display: inline;
  }
  .game-header__btn {
    a {
      line-height: initial;
      color: #be0000;
    }
  }
}
</style>
