import { isSP, isSPSmall } from "./common";

if (googletag && googletag.apiReady) {
  // GPT API can be called safely.
}

const slotsPcMain: googletag.Slot[] = []; // ゲーム外(PC)
const slotsPcGame: googletag.Slot[] = []; // ゲーム内(PC)
const slotsSpMain: googletag.Slot[] = []; // ゲーム外(スマホ)
const slotsSpBigGame: googletag.Slot[] = []; // ゲーム内(スマホ大)
const slotsSpSmallGame: googletag.Slot[] = []; // ゲーム内(スマホ小)

let siteName = "toripop";
let needsSingleRequest = false;

export const setSiteName = (value: string) => {
  if (`${window.location.origin}` != "https://toripop.placehub.co") {
    // 本番以外
    siteName = value + "stg";
  } else {
    siteName = value;
  }
};

export const setNeedsSingleRequest = (value: boolean) => {
  needsSingleRequest = value;
}

const loadPcMainAd = () => {
  googletag.cmd.push(function () {
    // PC(メイン)
    // slotsPcMain[0] = googletag.defineSlot('/2062226/toripop/pc_toripop_728x90_1', [728, 90], 'ad_top_1').addService(googletag.pubads());
    // slotsPcMain[1] = googletag.defineSlot('/2062226/toripop/pc_toripop_728x90_2_under', [728, 90], 'ad_bottom_1').addService(googletag.pubads());
    slotsPcMain[2] = googletag.defineSlot('/2062226/toripop/pc_toripop_under_300x250_1', [300, 250], 'ad_under_1').addService(googletag.pubads());
    slotsPcMain[3] = googletag.defineSlot('/2062226/toripop/pc_toripop_under_300x250_2', [300, 250], 'ad_under_2').addService(googletag.pubads());
    slotsPcMain[4] = googletag.defineSlot('/2062226/toripop/pc_toripop_under_300x250_3', [300, 250], 'ad_under_4').addService(googletag.pubads()); // ad_under_3はスマホのみ
    slotsPcMain[5] = googletag.defineSlot('/2062226/toripop/pc_toripop_under_300x250_4', [300, 250], 'ad_under_5').addService(googletag.pubads());
    slotsPcMain[6] = googletag.defineSlot('/2062226/toripop/pc_toripop_right_300x250_1', [300, 250], 'ad_right_1').addService(googletag.pubads());
    slotsPcMain[7] = googletag.defineSlot('/2062226/toripop/pc_toripop_right_300x250_2', [300, 250], 'ad_right_2').addService(googletag.pubads());
    slotsPcMain[8] = googletag.defineSlot('/2062226/toripop/pc_toripop_right_300x250_3', [300, 250], 'ad_right_3').addService(googletag.pubads());
    if (needsSingleRequest) {
      googletag.pubads().enableSingleRequest();
    }
    googletag.pubads().setTargeting('tpsite', [siteName]);
    googletag.enableServices();
    // googletag.display("ad_top_1");
    // googletag.display("ad_bottom_1");
    googletag.display("ad_under_1");
    googletag.display("ad_under_2");
    googletag.display("ad_under_4");
    googletag.display("ad_under_5");
    googletag.display("ad_right_1");
    googletag.display("ad_right_2");
    googletag.display("ad_right_3");
  });
};

const loadPcGameAd = () => {
  googletag.cmd.push(function () {
    // PC(ゲーム内)
    slotsPcGame[0] = googletag.defineSlot('/2062226/toripop/pc_toripop_finish_300x250_1', [300, 250], 'ad_gameover_1').addService(googletag.pubads());
    slotsPcGame[1] = googletag.defineSlot('/2062226/toripop/pc_toripop_finish_300x250_2', [300, 250], 'ad_gameover_2').addService(googletag.pubads());
    slotsPcGame[2] = googletag.defineSlot('/2062226/toripop/pc_toripop_finish_after_300x250_1', [300, 250], 'ad_result_1').addService(googletag.pubads());
    slotsPcGame[3] = googletag.defineSlot('/2062226/toripop/pc_toripop_finish_after_300x250_2', [300, 250], 'ad_result_2').addService(googletag.pubads());
    slotsPcGame[4] = googletag.defineSlot('/2062226/toripop/pc_toripop_finish_300x250_1', [300, 250], 'ad_clear_1').addService(googletag.pubads());
    slotsPcGame[5] = googletag.defineSlot('/2062226/toripop/pc_toripop_finish_300x250_1', [300, 250], 'ad_clear_2').addService(googletag.pubads());
    if (needsSingleRequest) {
      googletag.pubads().enableSingleRequest();
    }
    googletag.pubads().setTargeting('tpsite', [siteName]);
    googletag.enableServices();
    googletag.display("ad_gameover_1");
    googletag.display("ad_gameover_2");
    googletag.display("ad_result_1");
    googletag.display("ad_result_2");
    googletag.display("ad_clear_1");
    googletag.display("ad_clear_2");
  });
}

const loadSpMainAd = () => {
  googletag.cmd.push(function () {
    // SP(メイン)
    slotsSpMain[0] = googletag.defineSlot('/2062226/toripop/sp_toripop_300x250_1', [300, 250], 'ad_under_2').addService(googletag.pubads());
    slotsSpMain[1] = googletag.defineSlot('/2062226/toripop/sp_toripop_300x250_2', [300, 250], 'ad_under_3').addService(googletag.pubads());
    slotsSpMain[2] = googletag.defineSlot('/2062226/toripop/sp_toripop_300x250_3', [300, 250], 'ad_under_5').addService(googletag.pubads());
    if (needsSingleRequest) {
      googletag.pubads().enableSingleRequest();
    }
    googletag.pubads().setTargeting('tpsite', [siteName]);
    googletag.enableServices();
    googletag.display("ad_under_2");
    googletag.display("ad_under_3");
    googletag.display("ad_under_5");
  });
};

const loadSpBigGameAd = () => {
  googletag.cmd.push(function () {
    // SP大(ゲーム内)
    slotsSpBigGame[0] = googletag.defineSlot('/2062226/toripop/sp_toripop_finish_300x250_1', [300, 250], 'ad_gameover_1').addService(googletag.pubads());
    slotsSpBigGame[1] = googletag.defineSlot('/2062226/toripop/sp_toripop_finish_after_300x250_1', [300, 250], 'ad_result_1').addService(googletag.pubads());
    slotsSpBigGame[2] = googletag.defineSlot('/2062226/toripop/sp_toripop_finish_300x250_1', [300, 250], 'ad_clear_1').addService(googletag.pubads());
    if (needsSingleRequest) {
      googletag.pubads().enableSingleRequest();
    }
    googletag.pubads().setTargeting('tpsite', [siteName]);
    googletag.enableServices();
    googletag.display("ad_gameover_1");
    googletag.display("ad_result_1");
    googletag.display("ad_clear_1");
  });
}

const loadSpSmallGameAd = () => {
  googletag.cmd.push(function () {
    // SP小(ゲーム内)        
    slotsSpSmallGame[0] = googletag.defineSlot('/2062226/toripop/sp_toripop_finish_320x100_1', [320, 100], 'ad_gameover_1').addService(googletag.pubads());
    slotsSpSmallGame[1] = googletag.defineSlot('/2062226/toripop/sp_toripop_finish_after_320x100_1', [320, 100], 'ad_result_1').addService(googletag.pubads());
    slotsSpSmallGame[2] = googletag.defineSlot('/2062226/toripop/sp_toripop_finish_320x100_1', [320, 100], 'ad_clear_1').addService(googletag.pubads());
    if (needsSingleRequest) {
      googletag.pubads().enableSingleRequest();
    }
    googletag.pubads().setTargeting('tpsite', [siteName]);
    googletag.enableServices();
    googletag.display("ad_gameover_1");
    googletag.display("ad_result_1");
    googletag.display("ad_clear_1");
  });
}

export const loadMainAd = () => {
  if (isSP()) {
    loadSpMainAd();
  } else {
    loadPcMainAd();
  }
};

export const loadGameAd = () => {
  if (isSPSmall()) {
    loadSpSmallGameAd();
  } else if (isSP()) {
    loadSpBigGameAd();
  } else {
    loadPcGameAd();
  }
};

// ゲーム外リロード
export const refreshMainAd = () => {
  if (isSP()) {
    googletag.cmd.push(() => googletag.pubads().refresh(slotsSpMain));
  } else {
    googletag.cmd.push(() => googletag.pubads().refresh(slotsPcMain));
  }
};

// ゲーム内リロード
export const refreshGameAd = () => {
  if (isSPSmall()) {
    googletag.cmd.push(() => googletag.pubads().refresh(slotsSpSmallGame));
  } else if (isSP()) {
    googletag.cmd.push(() => googletag.pubads().refresh(slotsSpBigGame));
  } else {
    googletag.cmd.push(() => googletag.pubads().refresh(slotsPcGame));
  }
};

