<template>
  <div
    :class="{
      balloon: is_balloon,
      rakuten: is_rakuten,
      tori: is_tori,
    }"
  >
    <div id="wapper">
      <RakutenHeader v-if="is_rakuten" />
      <Header
        :is_balloon="is_balloon"
        :is_rakuten="is_rakuten"
        :is_tori="is_tori"
      />
      <div id="container">
        <div id="history">
          <div class="subsection">
            <h3 class="section-header">{{ game_name }} 獲得履歴</h3>
            <p class="desc" v-if="point_histories.length > 0">履歴は過去30件まで遡って確認が可能です。</p>
            <p class="desc" v-else>履歴はまだありません。</p>
            <table class="history" v-if="point_histories.length > 0">
              <thead>
                <tr>
                  <th class="date">日時</th>
                  <th class="reason">内容</th>
                  <th class="point">獲得点数</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="point_history in point_histories" :key="point_history.id">
                  <td class="date">{{ parsePlayDate(point_history.given_at) }}</td>
                  <td class="reason">{{ reasonEnToJa(point_history.reason) }}</td>
                  <td class="point">{{ point_history.point }}点</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "@/views/parts/Header";
import Footer from "@/views/parts/Footer";
import RakutenHeader from "@/views/parts/RakutenHeader";
import Api from "@/lib/api";
import utils from "@/packs/mixins/utils"

export default {
  name: "Contact",
  components: {
    Header,
    Footer,
    RakutenHeader,
  },
  mixins: [
    utils
  ],
  data() {
    return {
      site_name: "",
      point_histories: [],
    };
  },
  computed: {
    is_rakuten: function () {
      // 楽天か
      return this.site_name === "rakuten";
    },
    is_balloon: function () {
      // バルーンポップか
      return this.site_name === "phalanx_saison";
    },
    is_tori: function () {
      // トリポップか
      return !(this.is_balloon || this.is_rakuten);
    },
    game_name: function () {
      // ゲーム名
      return this.is_rakuten ? "進め！幻の海底神殿" : this.is_balloon ? "バルーンポップ" : "トリポップ";
    },
  },
  methods: {
    reasonEnToJa: function (str) {
      switch (str) {
        case 'get_by_game':
          return 'ゲームによる獲得';
        case 'exhange':
          return '交換';
        case 'get_manually':
          return '手動付与';
        default:
          return '';
      }
    },
    setUser: function (info) {
      this.site_name = info.data.site_name;
    },
    callGetUserApi: function () {
      Api.getUser(this, this.setUser);
    },
    setPointHistories: function (info) {
      this.point_histories = info.data;
    },
    callGetPointHistoriesApi: function () {
      Api.getPointHistories(this, this.setPointHistories);
    },
  },
  mounted: function () {
    this.callGetUserApi();
    this.callGetPointHistoriesApi();
  },
};
</script>

<style lang="scss"></style>
