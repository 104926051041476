import { API_URL } from "./common";

import $ from "jquery";

export const initScreen = () => {
  $("#comfirm_btn").prop("disabled", true);

  $("#agree_ckeckbox").on("click", function () {
    if ($(this).prop("checked") == false) {
      $("#comfirm_btn").prop("disabled", true);
    } else {
      $("#comfirm_btn").prop("disabled", false);
    }
  });

  $("#contact_form").on("submit", (e: any) => {
    e.preventDefault();

    if (!confirm("お問い合わせ内容を送信します。よろしいですか？")) {
      return;
    }

    const contact_data = $("#contact_form").serialize();
    console.log(contact_data);
    $.ajax({
      url: API_URL() + "/contacts",
      method: "POST",
      data: contact_data,
      dataType: "json",
      cache: false,
      xhrFields: {
        withCredentials: true,
      },
      beforeSend: () => {
        $("#comfirm_btn").prop("disabled", true);
      },
    }).then(
      (data) => {
        data; // dummy read
        alert("お問い合わせを受付ました。");
        ($("#contact_form")[0] as HTMLFormElement).reset();
      },
      (error) => {
        error; // dummy read
        alert("お問い合わせを送信できませんでした。");
      }
    );
  });
};
