<template>
  <div class="selectItemBody">
    <ul class="selectItemBody_items">
      <li
        class="selectItemBody_item"
        v-for="item in items"
        :key="item.point_type"
      >
        <button class="selectItemBody_item-button" @click="selectItem(item)">
          <div class="selectItemBody_item-image">
            <img :src="item.image_src" />
          </div>
          <div class="selectItemBody_item-point">{{ item.medals }}メダル</div>
          <div class="selectItemBody_item-name">{{ item.name }}</div>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "selectItemMain",
  components: {},
  props: {
    medals: Number,
  },
  data() {
    return {
      items: [
        {
          point_type: 1,
          name: "ハイスピード",
          desc: "自分の周りの時間を加速し、機動力を上げるアイテム。このアイテムを使うと、サイコロで出た目の2倍進むことができる。",
          medals: 200,
          image_src: require("@/assets/images/balloon/item/item_master_1.png"),
        },
        {
          point_type: 2,
          name: "ダッシュ",
          desc: "常にトップスピードで走れるようになるアイテム。このアイテムを使うと、必ず6マス進むことができる。",
          medals: 100,
          image_src: require("@/assets/images/balloon/item/item_master_2.png"),
        },
        {
          point_type: 3,
          name: "オーバーフロー",
          desc: "限界を超えた力を発揮できるアイテム。このアイテムを使うと、サイコロで出た目+2進むことができる。",
          medals: 60,
          image_src: require("@/assets/images/balloon/item/item_master_3.png"),
        },
        {
          point_type: 4,
          name: "ロックオン",
          desc: "神の力が宿ったアイテム。このアイテムを使うと、サイコロの目を1～6まで自由に指定できる。",
          medals: 300,
          image_src: require("@/assets/images/balloon/item/item_master_4.png"),
        },
        {
          point_type: 5,
          name: "ガード",
          desc: "あらゆる災難から身を守るアイテム。このアイテムを使うと、使用した週の間、戻るマスを無効化できる。",
          medals: 60,
          image_src: require("@/assets/images/balloon/item/item_master_5.png"),
        },
        {
          point_type: 6,
          name: "デュアルダイス",
          desc: "対象を複数に分身させる魔法のアイテム。このアイテムを使うと、サイコロを2個振ることができる。",
          medals: 150,
          image_src: require("@/assets/images/balloon/item/item_master_6.png"),
        },
        {
          point_type: 7,
          name: "トリプルダイス",
          desc: "対象を複数に分身させる魔法のアイテム。このアイテムを使うと、サイコロを3個振ることができる。",
          medals: 250,
          image_src: require("@/assets/images/balloon/item/item_master_7.png"),
        },
        {
          point_type: 8,
          name: "クアッドダイス",
          desc: "対象を複数に分身させる魔法のアイテム。このアイテムを使うと、サイコロを4個振ることができる。",
          medals: 450,
          image_src: require("@/assets/images/balloon/item/item_master_8.png"),
        },
        {
          point_type: 17,
          name: "スーパーダイス",
          desc: "対象を複数に分身させる魔法のアイテム。このアイテムを使うと、サイコロを5個振ることができる。",
          medals: 800,
          image_src: require("@/assets/images/balloon/item/item_master_17.png"),
        },
        {
          point_type: 9,
          name: "ラッシュ",
          desc: "トップスピードを超えた速度で走れるようになる魔法のアイテム。このアイテムを使うと、必ず10マス進むことができる。",
          medals: 150,
          image_src: require("@/assets/images/balloon/item/item_master_9.png"),
        },
        {
          point_type: 21,
          name: "クラッシャー",
          desc: "猛スピードで走れるようになるアイテム。このアイテムを使うと、必ず15マス進むことができる。",
          medals: 200,
          image_src: require("@/assets/images/balloon/item/item_master_21.png"),
        },
        {
          point_type: 10,
          name: "オーバードライブ",
          desc: "限界を大きく超えた力を発揮できる魔法のアイテム。このアイテムを使うと、サイコロで出た目よりさらに6マス多く進むことができる。",
          medals: 50,
          image_src: require("@/assets/images/balloon/item/item_master_10.png"),
        },
        {
          point_type: 11,
          name: "リトルラック",
          desc: "少しの幸運が訪れるとされる初級魔法のアイテム。このアイテムを使うと、必ず4以上の目が出るようになる。",
          medals: 30,
          image_src: require("@/assets/images/balloon/item/item_master_11.png"),
        },
        {
          point_type: 12,
          name: "アクセレート",
          desc: "長時間効果がある加速魔法のアイテム。このアイテムを使うと、使用した週の間、1マス多く進める。他のアイテムと併用可。",
          medals: 70,
          image_src: require("@/assets/images/balloon/item/item_master_12.png"),
        },
        {
          point_type: 24,
          name: "ハイ＆ロー",
          desc: "ギャンブラーが自分の欲求のために作り出したアイテム。このアイテムを使うと、サイコロが奇数なら1マス、偶数なら8マス進める。",
          medals: 100,
          image_src: require("@/assets/images/balloon/item/item_master_24.png"),
        },
        {
          point_type: 15,
          name: "トランスフォーム",
          desc: "変化魔法のアイテム。このアイテムを使うと、ランダムでアイテムを入手できる。ただし、有効期限はこのアイテムのものが適用される。",
          medals: 50,
          image_src: require("@/assets/images/balloon/item/item_master_15.png"),
        },
      ],
      selected_item: null,
    };
  },
  computed: {},
  methods: {
    close: function () {
      this.$emit("close");
    },
    selectItem: function (item) {
      this.$emit("selectItem", item);
    },
  },
  watch: {},
  mounted: function () {
    // nothing
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/stylesheets/balloon/colors.scss";

.selectItemBody {
  height: 100%;
  &_items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 12px;
  }
  &_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 87px;
    padding: 30px 0;
    cursor: pointer;
    @media screen and (max-width: 414px) {
      padding-top: 4px;
      padding-bottom: 4px;
    }
    @media screen and (max-width: 320px) {
      width: 72px;
      padding-top: 4px;
      padding-bottom: 4px;
    }
    &-button {
      padding: 0;
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none;
      appearance: none;
      font-weight: 900;
    }
    &-image {
      margin: 4px 0;
      img {
        width: 100%;
      }
    }
    &-point {
      margin: 2px 0;
      color: $accent-color;
      font-size: 0.8rem;
      text-shadow: 2px 2px 1px #003366, -2px 2px 1px #003366,
        2px -2px 1px #003366, -2px -2px 1px #003366, 2px 0px 1px #003366,
        0px 2px 1px #003366, -2px 0px 1px #003366, 0px -2px 1px #003366;
      letter-spacing: 0.1rem;
    }
    &-name {
      margin: 2px 0;
      color: $text-color-reverse;
      font-size: 0.5rem;
      text-shadow: 2px 2px 1px #003366, -2px 2px 1px #003366,
        2px -2px 1px #003366, -2px -2px 1px #003366, 2px 0px 1px #003366,
        0px 2px 1px #003366, -2px 0px 1px #003366, 0px -2px 1px #003366;
    }
  }
}
</style>
