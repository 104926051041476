<template>
  <header id="header">
    <div class="box">
      <a
        href="https://www.a-q-f.com/openpc/sugoroku/index.html?incd=header_gnav_sugoroku"
        class="pc"
        v-if="is_balloon"
      >
        <img id="media_logo" src="../../assets/images/balloon/media_logo.png" />
      </a>
      <div class="">
        <img
          id="logo"
          src="../../assets/images/balloon/logo.png"
          v-if="is_balloon"
        />
        <img
          id="logo"
          src="../../assets/images/rakuten/logo.svg"
          v-if="is_rakuten"
        />
        <img id="logo" src="../../assets/images/tori/logo.png" v-if="is_tori" />
      </div>
      <div class="" v-if="is_show_subtitles">
        <div id="title">ゲームを遊んで{{ unit_name }}を獲得しよう♪</div>
        <div class="subtitle">
          {{ subtitle_first }}
        </div>
        <div class="subtitle">{{ subsubtitle }}</div>
      </div>
    </div>
    <div id="contact_link" v-if="is_show_contact">
      <router-link
        :to="{
          name: 'Contact',
        }"
        >お問合せ</router-link
      >
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  components: {},
  props: {
    is_balloon: {
      type: Boolean,
      default: false,
    },
    is_rakuten: {
      type: Boolean,
      default: false,
    },
    is_tori: {
      type: Boolean,
      default: false,
    },
    is_show_contact: {
      type: Boolean,
      default: false,
    },
    unit_name: String,
    subtitle_first: String,
    subsubtitle: String,
  },
  data() {
    return {};
  },
  computed: {
    is_show_subtitles: function () {
      return (
        this.unit_name !== undefined &&
        this.subtitle_first !== undefined &&
        this.subsubtitle !== undefined
      );
    },
  },
  methods: {},
  watch: {},
  mounted: function () {
    // nothing
  },
};
</script>

<style lang="scss" scoped></style>
