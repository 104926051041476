<template>
  <div class="selectItemHeader">
    <div class="selectItemHeader_point">
      <div class="selectItemHeader_point-title">現在の所持メダル</div>
      <div class="selectItemHeader_point-point">
        {{ medals }}<span class="selectItemHeader_point-unit">メダル</span>
      </div>
    </div>
    <div class="selectItemHeader_buttons">
      <button
        class="selectItemHeader_backButton"
        @click="back"
        v-if="is_select_item_main"
      >
        <img src="~@/assets/images/balloon/btn_back_to_game.svg" />
      </button>
      <button class="selectItemHeader_backButton" @click="back" v-else>
        <img src="~@/assets/images/balloon/btn_back.svg" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectItemHeader",
  components: {},
  props: {
    medals: Number,
    is_select_item_main: Boolean,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    back: function () {
      this.$emit("back");
    },
  },
  watch: {},
  mounted: function () {
    // nothing
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/stylesheets/balloon/colors.scss";

.selectItemHeader {
  display: flex;
  justify-content: space-between;
  height: 84px;
  @media screen and (max-width: 414px) {
    height: 56px;
  }
  @media screen and (max-width: 320px) {
    height: 44px;
  }
  &_point {
    display: flex;
    align-items: center;
    width: 360px;
    background: url("~@/assets/images/balloon/bg_point.svg") center center /
      cover no-repeat;
    @media screen and (max-width: 414px) {
      width: 239px;
    }
    @media screen and (max-width: 320px) {
      width: 200px;
    }
    &-title {
      margin: 0 12px;
      padding: 4px 10px 4px 4px;
      color: $text-color-reverse;
      font-size: 1.2rem;
      background: url("~@/assets/images/balloon/bg_point-title.svg") center
        center / cover no-repeat;
      @media screen and (max-width: 414px) {
        padding-right: 18px;
        font-size: 0.8rem;
      }
      @media screen and (max-width: 320px) {
        padding-right: 12px;
        font-size: 0.6rem;
      }
    }
    &-point {
      color: $accent-color;
      font-size: 2.5rem;
      text-shadow: 2px 2px 1px #003366, -2px 2px 1px #003366,
        2px -2px 1px #003366, -2px -2px 1px #003366, 2px 0px 1px #003366,
        0px 2px 1px #003366, -2px 0px 1px #003366, 0px -2px 1px #003366;
      @media screen and (max-width: 414px) {
        font-size: 1.5rem;
      }
      @media screen and (max-width: 320px) {
        font-size: 1.2rem;
      }
    }
    &-unit {
      color: $text-color-reverse;
      font-size: 1.2rem;
      @media screen and (max-width: 414px) {
        font-size: 0.6rem;
      }
      @media screen and (max-width: 320px) {
        font-size: 0.6rem;
      }
    }
  }
  &_buttons {
    display: flex;
    margin: 16px 16px 0 0;
    @media screen and (max-width: 414px) {
      margin: 8px 8px 0 0;
    }
    @media screen and (max-width: 320px) {
      margin: 8px 8px 0 0;
    }
  }
  &_backButton {
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    appearance: none;
    img {
      width: 206px;
      height: 52px;
      cursor: pointer;
      @media screen and (max-width: 414px) {
        width: 103px;
        height: 26px;
      }
      @media screen and (max-width: 320px) {
        width: 77px;
        height: 20px;
      }
    }
  }
}
</style>
