import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueScrollTo from "vue-scrollto";
import VueGtm from 'vue-gtm';

const app = createApp(App);
app.use(router);
app.use(VueScrollTo, VueScrollTo, {
  duration: 1000,
  easing: "ease",
});
app.use(VueGtm, {
  id: 'GTM-WP388M4',
});
app.mount("#app");

if (`${window.location.origin}` == "https://toripop.placehub.co") {
  // 本番
  app.config.globalProperties.$baseApiURL =
    "https://api.toripop.placehub.co/v1";
} else if (
  `${window.location.origin}` == "https://stg-www.toripop.placehub.co"
) {
  // ステージング
  app.config.globalProperties.$baseApiURL =
    "https://stg-api.toripop.placehub.co/v1";
} else {
  app.config.globalProperties.$baseApiURL = "http://localhost:3000/v1";
}
