import axios from "axios";

function getUser(global, callback) {
  callGet(global, "/user", callback);
}

function getPointHistories(global, callback) {
  callGet(global, "/point_histories/", callback);
}

function postScore(global, point, stage, status, callback) {
  var params = new URLSearchParams();
  params.append("point", point);
  params.append("stage", stage);
  params.append("status", status);

  callPost(global, "/point/add", params, callback);
}

function exhange(global, point_type, quantity, callback) {
  var params = new URLSearchParams();
  params.append("point_type", point_type);
  params.append("quantity", quantity);

  callPost(global, "/point/exchange", params, callback);
}

function callGet(global, url, callback) {
  axios
    .get(global.$baseApiURL + url, { withCredentials: true })
    .then((res) => {
      console.log(res);
      callback(res);
    })
    .catch((e) => {
      console.log("Error occurred in API");
      console.log(e);
    });
}

function callPost(global, url, params, callback) {
  axios
    .post(global.$baseApiURL + url, params, { withCredentials: true })
    .then((res) => {
      console.log(res);
      callback(res);
    })
    .catch((e) => {
      console.log("Error occurred in API");
      console.log(e);
    });
}

function callPut(global, url, params, callback) {
  axios
    .put(global.$baseApiURL + url, params, { withCredentials: true })
    .then((res) => {
      console.log(res);
      callback(res);
    })
    .catch((e) => {
      console.log("Error occurred in API");
      console.log(e);
    });
}

export default {
  getUser,
  getPointHistories,
  postScore,
  exhange,
};
