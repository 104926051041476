import {
  loadMainAd,
  loadGameAd,
  refreshMainAd,
  refreshGameAd,
} from "./ad";
import {
  isSP,
  // isSPSmall,
  // API_URL,
} from "./common";

import $ from "jquery";
require("jquery.transit");

const debugmode = false; // TODO debug

// パイプの隙間の距離(画面サイズに対してのの割合。この割合に鳥1つ分のサイズを加えたものが隙間になる。)
const pipeHeightRatio = {
  stage1: 3 / 5,
  stage2: 1 / 2,
  stage3: 2 / 5,
};

// 鳥の初期位置(1 : 最上部、0 : 最下部)(画面サイズに対しての割合)
const startPositionRatio = 1 / 4;

// 最初のパイプのみ高さ固定(画面サイズに対してのの割合)
const firstPipePositionRatio = 1 / 4;

const clearScore = 10;

// ポップアップのフェードインアニメーション時間
const fadeinMillisec = 2000;
// startボタンをアクティブにする時間
const startButtonActiveMillisec = 5000;
// nextボタンやtopへボタンなどstart以外のボタンアクティブにする時間
const nextButtonActiveMillisec = 3000;
// メッセージのフェードインアニメーション時間
const messageMillisec = 2000;
// 広告リロード時間
// let refreshAdMillisec = 16000;

// PC時のゲームオーバーポップアップを瞬時に出す確率 (0.1だと10回に1回瞬時に出す。)
const rateShowGameOverimmediatelyPc = 1.0;

// PC時のステージクリアポップアップを瞬時に出す確率 (0.1だと10回に1回瞬時に出す。)
const rateShowStageClearimmediatelyPc = 1.0;

// SP時のゲームオーバーポップアップを瞬時に出す確率 (0.1だと10回に1回瞬時に出す。)
const rateShowGameOverimmediatelySp = 1.0;

// SP時のステージクリアポップアップを瞬時に出す確率 (0.1だと10回に1回瞬時に出す。)
const rateShowStageClearimmediatelySp = 1.0;

const states = Object.freeze({
  SplashScreen: 0,
  PlayGuideScreen: 1,
  CountDonw: 2,
  ReadyScreen: 3,
  GameScreen: 4,
  NextStageScreen: 5,
  GameOverScreen: 6,
  ScoreCheckScreen: 7,
  PointCheckScreen: 8,
});

const gravity = 0.25;
const jump = -4.6;
const pipewidth = 52;

let flyAreaHeight: number;
let playerHeight: number;
let startPosition: number;
let currentstate: number;
let velocity: number;
let position: number;
let rotation: number;
let score: number;
let totalScore: number;
let stage: number;
let pipeheight: number;
let pipes: any = [];
//loops
let loopGameloop: number;
let loopPipeloop: number;
// let refreshAdloop: number;
let isFirstPlay = true;
let isShowPopupImmediately = true;
let postScore: (value: number, stage: number, status: string) => void;

let isLoadAdWithStartGame = true;
let canPlay = true;

// ゲーム途中の場合のサーバから取得した現在のスコア
let playingGameScore = 0;

export const setIsLoadAdWithStartGame = (value: boolean) => {
  isLoadAdWithStartGame = value;
}

export const setCanPlay = (value: boolean) => {
  canPlay = value;
}

export const setPlayingGameScore = (value: number) => {
  playingGameScore = value;
}

export const initScreen = () => {
  $(document).ready(() => {
    // if (window.location.search == "?debug") {
    //   debugmode = true;
    // }
    // if (window.location.search == "?easy") {
    //   clearScore = 1;
    // }

    if (playingGameScore > 0) { // 継続の場合
      // パラメタ初期化
      initContinueStart();
      // ステージ開始前の初期化
      initStaegParam();    
      // スプラッシュ表示
      showReady(false);
      // ポップアップ上の広告を先に読み込んでおく
      loadGameAd();
    } else { // 新規の場合
      // パラメタ初期化
      initNomalStart();
      // ステージ開始前の初期化
      initStaegParam();
      // スプラッシュ表示
      showSplash();
      // ポップアップ上の広告を先に読み込んでおく
      loadGameAd();
    }
  
  });

  $(window).on("load", () => {
    // nothing
  });

  $("#start").on("click", () => {
    if (currentstate != states.SplashScreen) {
      return;
    }
    setTimeout(() => {

      showReady(true);

    }, 10);
  });

  $("#gamescreen").on("click", () => {
    if (currentstate == states.GameScreen) {
      playerJump();
    } else if (currentstate == states.ReadyScreen) {
      startGame();
    }
  });

  $("#next_play_guide_1").on("click", () => {
    setTimeout(() => {
      if (currentstate == states.PlayGuideScreen) {
        $("#play_guide_1").css({ display: "none" });
        $("#play_guide_2").css({ display: "block" });
      }
    }, 10);
  });

  $("#next_play_guide_2").on("click", () => {
    setTimeout(() => {
      if (currentstate == states.PlayGuideScreen) {
        $("#play_guide_2").css({ display: "none" });

        // ステータス変更
        currentstate = states.CountDonw;
        // カウウントダウン表示
        showCountdown();
      }
    }, 10);
  });

  $("#showscore").on("click", () => {
    if (currentstate != states.GameOverScreen) {
      return;
    }

    // hide popup
    $("#gameover").css({ display: "none" });

    //start the game over!
    showScore();

  });

  $("#gototop").on("click", () => {
    if (currentstate != states.ScoreCheckScreen) {
      return;
    }

    // hide popup
    $("#scorecheck").css({ display: "none" });

    // refresh main ad
    refreshMainAd();

    // refresh game ad
    refreshGameAd();

    // パラメタ初期化
    initNomalStart();
    // ステージ開始前の初期化
    initStaegParam();
    // スプラッシュ表示
    showSplash();
  });

  $("#next").on("click", () => {
    if (currentstate != states.NextStageScreen) {
      return;
    }

    setTimeout(() => {
      // hide popup
      $("#stageclear").css({ display: "none" });

      // ステータス変更
      currentstate = states.CountDonw
      // カウウントダウン表示
      showCountdown();

      // load game ad(先に読み込んでおく)
      refreshGameAd();

      if (isLoadAdWithStartGame || isSP()) { // スタート時にロードするサイトなら広告ロード(SPなら無条件で広告ロード)
        // refresh main ad
        refreshMainAd();
      }

    }, 10);
  });

  $("#showpoint").on("click", () => {
    if (currentstate != states.SplashScreen) {
      return;
    }

    $("#pointcheck").css({ top: "30px" });
    $("#pointcheck")
      .fadeIn({
        duration: fadeinMillisec,
        queue: false,
      })
      .animate(
        {
          top: 0,
        },
        {
          duration: fadeinMillisec,
          easing: "swing",
          complete: () => {
            currentstate = states.PointCheckScreen;

            // refresh main ad
            refreshMainAd();
          },
        }
      );
  });

  $("#closepoint").on("click", () => {
    if (currentstate != states.PointCheckScreen) {
      return;
    }
    setTimeout(() => {
      currentstate = states.SplashScreen;

      $("#pointcheck").css({ display: "none" });
    }, 10);
  });
};

export const initCallback = (callback: (value: number, stage: number, status: string) => void) => {
  postScore = callback;
};

export const setIsFirstPlay = (value: boolean) => {
  isFirstPlay = value;
};

export const setIsShowPopupImmediately = (value: boolean) => {
  isShowPopupImmediately = value;
};

export const afterSetupUser = () => {
  // load main ad
  loadMainAd();
};

const initNomalStart = () => {
  flyAreaHeight = $("#flyarea").height()!;
  playerHeight = $("#player").height()!;
  startPosition = (flyAreaHeight - playerHeight) * (1 - startPositionRatio);
  currentstate = states.SplashScreen;

  totalScore = 0;
  stage = 1;
};

const initContinueStart = () => {
  flyAreaHeight = $("#flyarea").height()!;
  playerHeight = $("#player").height()!;
  startPosition = (flyAreaHeight - playerHeight) * (1 - startPositionRatio);
  currentstate = states.SplashScreen;

  totalScore = playingGameScore;
  stage = Math.floor(playingGameScore / clearScore) + 1;
}

const initStaegParam = () => {
  // 各種初期化
  velocity = 0;
  position = startPosition;
  rotation = 0;
  score = 0;

  switch (stage) {
    case 1:
      pipeheight = flyAreaHeight * pipeHeightRatio.stage1 + playerHeight;
      break;
    case 2:
      pipeheight = flyAreaHeight * pipeHeightRatio.stage2 + playerHeight;
      break;
    case 3:
      pipeheight = flyAreaHeight * pipeHeightRatio.stage3 + playerHeight;
      break;
    default:
      pipeheight = flyAreaHeight * pipeHeightRatio.stage3 + playerHeight;
      break;
  }

  // トリを初期化
  $("#player").css({ y: 0, x: 0 });
  updatePlayer($("#player"));

  // pipeを初期化
  $(".pipe").remove();
  pipes = [];
}

const showSplash = () => {

  currentstate = states.SplashScreen;

  // アニメーションをストップ
  $(".animated").css("animation-play-state", "paused");
  $(".animated").css("-webkit-animation-play-state", "paused");

  // スプラッシュを表示
  $("#splash").css({ display: "block" });

  // スコア非表示
  $("#bigscore").css({ display: "none" });

  if (canPlay) { // 遊べるか
    // buttonを非アクティブ
    $("#start").addClass("btn_disabled");

    // buttonをアクティブ
    setTimeout(() => {
      $("#start").removeClass("btn_disabled");
    }, startButtonActiveMillisec);
  }
}

const showReady = (refresh: boolean) => {
  $("#splash").css({ display: "none" });
  setBigScore(false);

  if (isFirstPlay) {
    // プレイ説明表示
    $("#play_guide_1").css({ display: "block" });
    // ステータス変更
    currentstate = states.PlayGuideScreen;
  } else {
    // ステータス変更
    currentstate = states.CountDonw;
    // カウウントダウン表示
    showCountdown();
  }

  // アニメーションを開始
  $(".animated").css("animation-play-state", "running");
  $(".animated").css("-webkit-animation-play-state", "running");

  if (refresh && (isLoadAdWithStartGame || isSP())) { // スタート時にロードするサイトなら広告ロード(SPなら無条件で広告ロード)
    // refresh main ad
    refreshMainAd();
  }
}

const showCountdown = () => {
  
  let count = 3
  $("#countdown").html(String(count));
  $("#countdown").css({ display: "block" }); // カウントダウン表示
  const timerID = setInterval(() => {

    if (count <= 1) {
      clearInterval(timerID);

      $("#countdown").css({ display: "none" }); // カウントダウン非表示
      $("#bigscore").css({ display: "block" }); // スコア表示

      // ステータス変更
      currentstate = states.ReadyScreen;

      // スタートメッセージ表示
      showStartMessage("タップしてスタート！");
    } else {
      
      count--;
      $("#countdown").html(String(count));

    }
  }, 1000);
}

const startGame = () => {
  if (currentstate != states.ReadyScreen) {
    return;
  }
  currentstate = states.GameScreen;

  // デバッグモード
  if (debugmode) {
    $(".boundingbox").show();
  }

  // ループ開始
  const updaterate = 1000.0 / 60.0; //60 times a second

  clearInterval(loopGameloop); // ループの二重起動を防ぐために最初に必ずクリア
  clearInterval(loopPipeloop); // ループの二重起動を防ぐために最初に必ずクリア
  loopGameloop = window.setInterval(gameloop, updaterate);
  loopPipeloop = window.setInterval(updatePipes, 3000); // TODO pipeの出る間隔

  // 最初だけpipeをすぐに描写
  updatePipes(true);

  //jump from the start!
  playerJump();

  // スタートメッセージを消す
  hideStartMessage();
};

const showNextStage = () => {
  // ループ停止
  clearInterval(loopGameloop);
  clearInterval(loopPipeloop);

  // setFinalScore();

  // refresh main ad
  refreshMainAd();

  // buttonを非アクティブ
  $("#next").addClass("btn_disabled");

  const completeShowStageClear = () => {
    // buttonをアクティブ
    setTimeout(() => {
      $("#next").removeClass("btn_disabled");
    }, nextButtonActiveMillisec);
  };

  let rateShowStageClearimmediately;
  if (!isShowPopupImmediately) {
    // パッと出さない
    rateShowStageClearimmediately = 0;
  } else if (isSP()) {
    rateShowStageClearimmediately = rateShowStageClearimmediatelySp;
  } else {
    rateShowStageClearimmediately = rateShowStageClearimmediatelyPc;
  }

  // ステージクリアを表示
  if (Math.random() < rateShowStageClearimmediately) {
    $("#stageclear").css({ display: "block" });
    completeShowStageClear();
  } else {
    //fadeIn popup
    $("#stageclear").css({ top: "30px" });
    $("#stageclear")
      .fadeIn({
        duration: fadeinMillisec,
        queue: false,
      })
      .animate(
        {
          top: 0,
        },
        {
          duration: fadeinMillisec,
          easing: "swing",
          complete: () => {
            completeShowStageClear();
          },
        }
      );
  }
  
  currentstate = states.NextStageScreen;
  // TODO successの時に実施する処理があれば追加
  postScore(score, stage, 'playing');
  // ステージをインクリメント
  stage++;
  // ステージ開始前の初期化
  initStaegParam();
};

const updatePlayer = (player: JQuery) => {
  //rotation
  rotation = Math.min((velocity / 10) * 90, 90);

  //apply rotation and position
  player.css({ rotate: rotation, top: position });
};

const gameloop = () => {
  const player = $("#player");

  // update the player speed/position
  velocity += gravity;
  position += velocity;

  // update the player
  updatePlayer(player);

  // create the bounding box
  const box = document.getElementById("player")!.getBoundingClientRect();
  const origwidth = 68.0;
  const origheight = 68.0;

  const boxwidth = origwidth - Math.sin(Math.abs(rotation) / 90) * 8;
  const boxheight = (origheight + box.height) / 2;
  const boxleft = (box.width - boxwidth) / 2 + box.left;
  const boxtop = (box.height - boxheight) / 2 + box.top + window.pageYOffset; // box.topだとブラウザのウインドウの上からの相対位置のためwindow.pageYOffse(スクロール量)を足して絶対位置にする。
  const boxright = boxleft + boxwidth;
  const boxbottom = boxtop + boxheight;

  //if we're in debug mode, draw the bounding box
  if (debugmode) {
    const boundingbox = $("#playerbox");
    boundingbox.css("left", boxleft);
    boundingbox.css("top", boxtop);
    boundingbox.css("height", boxheight);
    boundingbox.css("width", boxwidth);
  }

  if (score >= clearScore) {
    if (stage < 3) {
      showNextStage();
      return;
    } else {
      showGameOver(true);
      return;
    }
  }

  //did we hit the ground?
  if (box.bottom >= $("#land").offset()!.top) {
    playerDead();
    return;
  }

  // have they tried to escape through the ceiling? :o
  const ceiling = $("#ceiling");
  if (boxtop <= ceiling.offset()!.top + ceiling.height()!) {
    playerDead();
    return;
  }

  //we can't go any further without a pipe
  if (pipes[0] == null) return;

  //determine the bounding box of the next pipes inner area
  const nextpipe = pipes[0];
  const nextpipeupper = nextpipe.children(".pipe_upper");

  const pipetop = nextpipeupper.offset().top + nextpipeupper.height();
  const pipeleft = nextpipeupper.offset().left - 2; // for some reason it starts at the inner pipes offset, not the outer pipes.
  const piperight = pipeleft + pipewidth;
  const pipebottom = pipetop + pipeheight;

  if (debugmode) {
    const boundingbox = $("#pipebox");
    boundingbox.css("left", pipeleft);
    boundingbox.css("top", pipetop);
    boundingbox.css("height", pipeheight);
    boundingbox.css("width", pipewidth);
  }

  //have we gotten inside the pipe yet?
  if (boxright > pipeleft) {
    //we're within the pipe, have we passed between upper and lower pipes?
    if (boxtop > pipetop && boxbottom < pipebottom) {
      //yeah! we're within bounds
    } else {
      //no! we touched the pipe
      playerDead();
      return;
    }
  }

  //have we passed the imminent danger?
  if (boxleft > piperight) {
    //yes, remove it
    pipes.splice(0, 1);

    //and score a point
    playerScore();

    showPlayerMessage("+2");
  }
};

const playerJump = () => {
  velocity = jump;
};

// TODO cssのdisplayのblock/noneで表示制御している記述もあるので、showBigScore/hideBigScoreを作って統一
const setBigScore = (erase: boolean) => {
  const elemscore = $("#bigscore");
  elemscore.empty();

  if (erase) return;

  elemscore.append(totalScore + "<span class='point'>点</span>");
};

// TODO cssのdisplayのblock/noneで表示制御している記述もあるので、showFinalScore/hideFinalScoreを作って統一
const setFinalScore = () => {
  const elemscore = $("#score_value");
  elemscore.empty();
  elemscore.append(totalScore + "<span class='point'>点</span>");
};

const playerDead = () => {
  // アニメーションをストップ
  $(".animated").css("animation-play-state", "paused");
  $(".animated").css("-webkit-animation-play-state", "paused");

  // トリを画面下まで移動
  const playerbottom = $("#player").position().top + $("#player").width()!; // トリが90度傾いているのでwidthを利用
  const floor = flyAreaHeight;
  const movey = Math.max(0, floor - playerbottom);
  (<any>$("#player")).transition(
    {
      y: movey + "px",
      rotate: 90,
    },
    1000,
    "easeInOutCubic",
    showGameOver(false)
  );

  if (totalScore == 0) {
    showMessage("遊べる回数 ノーカウント");
  }
};

const showStartMessage = (message: string) => {
  $("#startmessage").html(message);
  $("#startmessage").css({
    display: "block",
  });
};

const hideStartMessage = () => {
  $("#startmessage").css({
    display: "none",
  });
}


const showPlayerMessage = (message: string, showTime?: number, onComplete?: () => void) => {
  const position = $("#player").position();
  const top = position!.top;
  const left = position!.left;

  if (showTime === undefined) {
    showTime = messageMillisec;
  }

  $("#playermessage").html(message);
  $("#playermessage").css({
    display: "block",
    top: top - 40 + "px",
    left: left + "px",
  });
  $("#playermessage")
    .fadeOut({
      duration: showTime,
      queue: false,
    })
    .animate(
      {
        top: top - 50 + "px",
      },
      {
        duration: showTime,
        easing: "swing",
        complete: () => {
          $("#playermessage").css({ display: "none" });
          if (onComplete) onComplete();
        },
      }
    );
};

// TODO メッセージを固定位置ではなくプレイヤーの上などに表示する。
// (ただし、playerDeadの際などプレイヤーが大きくゲームエリア外に出てしまう場合があり、メッセージが表示されないのは困るため、ゲームエリア外に出てしまう事象と合わせて対応が必要)
const showMessage = (message: string, onComplete?: () => void) => {
  $("#message").html(message);
  $("#message").css({
    display: "block",
    bottom: "50px",
  });
  $("#message")
    .fadeOut({
      duration: messageMillisec,
      queue: false,
    })
    .animate(
      {
        bottom: "80px",
      },
      {
        duration: messageMillisec,
        easing: "swing",
        complete: () => {
          $("#message").css({ display: "none" });
          if (onComplete) onComplete();
        },
      }
    );
};

const showGameOver = (clear = false) => {
  // ループ停止
  clearInterval(loopGameloop);
  clearInterval(loopPipeloop);

  // プレイエリア内のスコア表示を非表示
  setBigScore(true);

  if (clear) {
    $("#unfortunately").html("全ステージクリア");
  } else {
    $("#unfortunately").html("ゲームオーバー");
  }

  // refresh main ad
  refreshMainAd();

  // buttonを非アクティブ
  $("#showscore").addClass("btn_disabled");

  const completeShowGameOver = () => {
    // buttonをアクティブ
    setTimeout(() => {
      $("#showscore").removeClass("btn_disabled");
    }, nextButtonActiveMillisec);
  };

  let rateShowGameOverimmediately;
  if (!isShowPopupImmediately) {
    // パッと出さない
    rateShowGameOverimmediately = 0;
  } else if (isSP()) {
    rateShowGameOverimmediately = rateShowGameOverimmediatelySp;
  } else {
    rateShowGameOverimmediately = rateShowGameOverimmediatelyPc;
  }

  if (Math.random() < rateShowGameOverimmediately) {
    $("#gameover").css({ display: "block" });
    completeShowGameOver();
  } else {
    //fadeIn popup
    $("#gameover").css({ top: "30px" });
    $("#gameover")
      .fadeIn({
        duration: fadeinMillisec,
        queue: false,
      })
      .animate(
        {
          top: 0,
        },
        {
          duration: fadeinMillisec,
          easing: "swing",
          complete: () => {
            completeShowGameOver();
          },
        }
      );
  }

  currentstate = states.GameOverScreen;

  // TODO successの時に実施する処理があれば追加
  postScore(score, stage, 'played');
};

const showScore = () => {
  // refresh main ad
  refreshMainAd();

  // buttonを非アクティブ
  $("#gototop").addClass("btn_disabled");

  //fadeIn popup
  $("#scorecheck").css({ top: "30px" });
  $("#scorecheck")
    .fadeIn({
      duration: fadeinMillisec,
      queue: false,
    })
    .animate(
      {
        top: 0,
      },
      {
        duration: fadeinMillisec,
        easing: "swing",
        complete: () => {
          currentstate = states.ScoreCheckScreen;

          // buttonをアクティブ
          setTimeout(() => {
            $("#gototop").removeClass("btn_disabled");
          }, nextButtonActiveMillisec);
        },
      }
    );

  //update the scoreboard
  setFinalScore();
};

const playerScore = () => {
  score += 2;
  totalScore += 2;
  setBigScore(false);
};

const updatePipes = (isFirst = false) => {
  // エリア外のpipeを削除
  $(".pipe")
    .filter(
      function () {
        return $(this).position().left <= 0;
      } // TODO アロー関数にする
    )
    .remove();

  // pipeを追加
  const constraint = flyAreaHeight - pipeheight;
  let topheight;
  let bottomheight;
  if (isFirst) {
    // 最初の場合は固定
    bottomheight = Math.floor(flyAreaHeight * firstPipePositionRatio);
    topheight = constraint - bottomheight;
  } else {
    // 2個目以降ランダム
    topheight = Math.floor(Math.random() * constraint);
    bottomheight = constraint - topheight;
  }

  const newpipe = $(
    '<div class="pipe animated"><div class="pipe_upper" style="height: ' +
      topheight +
      'px;"></div><div class="pipe_lower" style="height: ' +
      bottomheight +
      'px;"></div></div>'
  );
  $("#flyarea").append(newpipe);
  pipes.push(newpipe);
};
