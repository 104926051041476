<template>
  <div
    :class="{
      balloon: is_balloon,
      rakuten: is_rakuten,
      tori: is_tori,
    }"
  >
    <div id="wapper">
      <RakutenHeader v-if="is_rakuten" />
      <Header
        :is_balloon="is_balloon"
        :is_rakuten="is_rakuten"
        :is_tori="is_tori"
      />
      <div id="container">
        <div class="contact_wrapper">
          <div id="faq">
            <div class="subsection">
              <h3 class="section-header">よくある質問</h3>
              <div class="faq_list">
                <dl class="qa">
                <dt>タップしても勝手に落ちる。</dt>
                <dd>
                  <p>ゲーム表示にブラウザの処理が追いつかず、不安定になっている可能性がございます。<br />
                    ブラウザの拡張機能やキャッシュ設定など、動作に影響がありそうな設定を調整いただくと改善する可能性がございます。<br /><br />
                    また、通信環境の良い場所、広告が表示された後にプレイ開始いただけるとスムーズにプレイ頂けるかと思われます。  <br />
                    PCをご利用の場合はスマートフォンでプレイするなど、ご利用端末の変更などもお試しくださいませ。</p>
                </dd>
                </dl>
                <dl class="qa">
                <dt>ゲームの途中でしばらく放置すると回数が1回多く減っている。</dt>
                <dd>
                  <p>ゲーム、広告による離脱後も10分間は同一プレイとしてカウントしておりますが、10分以上放置するとステージ途中であっても次のステージは別のプレイとしてカウントされてしまう仕様になっております。</p>
                </dd>
                </dl>
                <dl class="qa">
                <dt>スコアが獲得履歴に反映されてない時がある。</dt>
                <dd>
                  <p>ネットワークの影響でサーバまでプレイ結果が到達していない可能性があります。<br />
                    ネットワーク環境に問題ないかご確認頂けますでしょうか。</p>
                </dd>
                </dl>
                <dl class="qa">
                <dt>プレイ中画面がフリーズしてしまい、そのままゲームオーバーになる。</dt>
                <dd>
                  <p>ゲーム表示にブラウザの処理が追いつかず、不安定になっている可能性がございます。<br />
                    ブラウザの拡張機能やキャッシュ設定など、動作に影響がありそうな設定を調整いただくと改善する可能性がございます。<br /><br />
                    また、通信環境の良い場所、広告が表示された後にプレイ開始いただけるとスムーズにプレイ頂けるかと思われます。<br />
                    PCをご利用の場合はスマートフォンでプレイするなど、ご利用端末の変更などもお試しくださいませ。</p>
                </dd>
                </dl>
                <dl class="qa">
                <dt>見たくない広告が出る。</dt>
                <dd>
                  <p>スポンサーリンク、広告の右上に表示されている▷×をタップ頂くと、「この広告について報告する」と表示されます。<br />
                    そちらより、表示された広告についてフィードバックをすることが出来ますので、お手数ですがそちらよりご報告頂けないでしょうか</p>
                </dd>
                </dl>
              </div>
            </div>
          </div>
          <div id="contact">
            <div class="subsection">
              <h3 class="section-header">{{ game_name }} お問い合わせ</h3>
              <form id="contact_form">
                <table class="table">
                  <tr>
                    <th class="title">
                      名前<span class="required">(必須)</span>
                    </th>
                    <td>
                      <input type="text" name="name" maxlength="255" required />
                    </td>
                  </tr>
                  <tr>
                    <th>メールアドレス<span class="required">(必須)</span></th>
                    <td>
                      <input type="email" name="email" maxlength="255" required />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      お問い合わせカテゴリ<span class="required">(必須)</span>
                    </th>
                    <td>
                      <select name="category" required>
                        <option value="">選択してください</option>
                        <option value="ご要望">ご要望</option>
                        <option value="不具合">不具合</option>
                        <option value="その他">その他</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>お問い合わせ内容<span class="required">(必須)</span></th>
                    <td>
                      <textarea
                        name="content"
                        rows="4"
                        cols="40"
                        maxlength="1024"
                        required
                      ></textarea>
                    </td>
                  </tr>
                  <tr>
                    <th>OS<span class="required">(必須)</span></th>
                    <td>
                      <select name="os" required>
                        <option value="">選択してください</option>
                        <option value="windows">Windows</option>
                        <option value="mac">Mac</option>
                        <option value="ios">iPhone/iPad</option>
                        <option value="android">Android</option>
                        <option value="else">その他</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>ブラウザ<span class="required">(必須)</span></th>
                    <td>
                      <select name="browser" required>
                        <option value="">選択してください</option>
                        <option value="chrome">Chrome</option>
                        <option value="safari">Safari</option>
                        <option value="edge">Edge</option>
                        <option value="else">その他</option>
                      </select>
                    </td>
                  </tr>
                </table>
                <div class="privacy">
                  <h2>個人情報保護方針</h2>
                  <div>
                    <p>
                      イーコイン株式会社（以下、「当社」という。）は、安心してご利用いただけるよう、全社員が個人情報保護・管理の重要性を認識し、個人情報に関する法令等を遵守するとともに、以下に示す個人情報保護方針を定めるとともに、個人情報保護マネジメントシステムを構築し、これを実行し、維持することを宣言します。
                    </p>
                    <p>
                      1.当社は、各事業を行う上で会員などから預かる個人情報及び社員の個人情報を保護するとの自覚を持ち、利用目的の達成に必要な範囲の適切な個人情報の取得、利用及び提供を行い、目的外利用を行わない措置を実施し、社会に信頼される企業となる。<br />
                      2.当社が保有する個人情報への不正アクセス、個人情報の漏えい、滅失又はき損の防止並びに是正措置を講じ、個人情報セキュリティ体制を継続的に向上する。<br />
                      3.個人情報に対する苦情及び相談に、適切、かつ迅速な対応を行う。<br />
                      4.個人情報に関する法令、国が定める指針及びその他の規範を遵守する。<br />
                      5.顧客のニーズ、最新の情報技術の動向を踏まえ、個人情報保護マネジメントシステムを適時・適正に見直し、継続的な改善を実施する。<br />
                      本方針は、当社内に掲示し、全従業員に周知させます。また、従業員各自の教育、啓発に努め個人情報保護意識の高揚を図ります。
                    </p>
                    <h3>個人情報の取扱について</h3>
                    <h5>1．個人情報の定義</h5>
                    <p>
                      イーコイン株式会社（以下、「当社」という。）は、個人情報とは、個人に関する基本情報（氏名、生年月日、その他の特定の個人を識別することができる情報）、ならびに特定の個人と結びついて使用されるメールアドレス、ユーザーＩＤ、パスワードなどの情報、および個人情報と一体となった趣味、家族構成、年齢その他の個人に関する属性情報であると認識しています。
                    </p>
                    <h5>2．クッキー・IPアドレス情報</h5>
                    <p>
                      1.当社Ｗｅｂサイトは、クッキー（Cookie）を使用させていただくことがあります。クッキーとは、Ｗｅｂページを利用したときに、ブラウザとサーバーとの間で送受信した利用履歴や入力内容などを、ご利用のコンピュータにファイルとして保存しておく仕組みです。クッキーの使用によって、ご利用者に適したコンテンツを表示することができるというメリットがあります。ブラウザの基本設定等を変更することによりクッキーを無効にすることができますが、当社Ｗｅｂサイトの機能またはサービスの一部がご利用になれない場合がございます。なお、クッキー・ＩＰアドレス情報については、当該情報単独で特定の個人を識別することができないため、当社では、個人情報とは認識しておりません。ただし、当該情報が個人情報と一体となって使用される場合には、当該情報も特定の個人を識別できるため個人情報とみなします。<br />
                      2.当社では、第三者に広告配信を委託する場合があり、これに関連して、当該第三者が当社Ｗｅｂサイトを訪問したお客様のクッキー情報等を取得し、利用している場合があります。この場合においては、当該第三者によって取得されたクッキー情報等は、当該第三者のプライバシーポリシーに従って取り扱われます。お客様は、当該第三者のウェブサイト内に設けられたオプトアウトページにアクセスして、当該第三者によるクッキー情報等の広告配信への利用を停止することができます。
                    </p>
                    <h5>３．SSLの使用について</h5>
                    <p>
                      個人情報の入力時には、セキュリティ確保のため、これらの情報が傍受、改ざんされることを防ぐ目的でSSL（Secure
                      Sockets Layer）を使用しております。<br />
                      ※SSLは情報を暗号化することで、盗聴防止やデータの改ざん防止を行うものです。SSLを利用する事で安全に情報を送信する事が可能となります。
                    </p>
                    <h5>4. 個人情報利用目的</h5>
                    <p>
                      当社が取扱う個人情報は、お問合せの返信時に利用します。<br />
                    </p>
                    <h5>5．個人情報利用の制限</h5>
                    <p>
                      当社は、あらかじめご本人の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取扱うことはありません。合併その他の理由により個人情報を取得した場合にも、あらかじめご本人の同意を得ないで、承継前の利用目的の範囲を超えて取扱うことはありません。ただし、次の場合はこの限りではありません。
                    </p>
                    <p>
                      1.法令に基づく場合<br />
                      2.人の生命、身体または財産の保護のために必要がある場合であって、ご本人の同意を得ることが困難であるとき<br />
                      3.公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ご本人の同意を得ることが困難であるとき<br />
                      4.国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ご本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
                    </p>
                    <h5>６．個人情報の適正な取得</h5>
                    <p>
                      当社は、適正に個人情報を取得し、偽りその他不正の手段により取得することはありません。
                    </p>
                    <h5>７．個人情報の取得に際する利用目的の通知と同意</h5>
                    <p>
                      当社は、個人情報を取得するにあたり、あらかじめその利用目的を公表し同意を得ます。ただし、次の場合はこの限りではありません。
                    </p>
                    <p>
                      1.利用目的をご本人に通知し、または公表することによりご本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合<br />
                      2.利用目的をご本人に通知し、または公表することにより当社の権利または正当な利益を害するおそれがある場合<br />
                      3.国の機関もしくは地方公共団体が法令の定める事務を遂行することに対して協力する必要がある場合であって、利用目的をご本人に通知し、または公表することにより当該事務の遂行に支障を及ぼすおそれがあるとき<br />
                      4.取得の状況からみて利用目的が明らかであると認められる場合
                    </p>
                    <h5>８．個人情報利用目的の変更の通知と同意</h5>
                    <p>
                      当社は、個人情報の利用目的を変更する場合には、変更前の利用目的と相当の関連性を有すると合理的に認められる範囲を超えては行わず、変更された利用目的について、ご本人に通知し、または公表し同意を得ます。
                    </p>
                    <h5>９．個人情報の安全管理・従業員の監督</h5>
                    <p>
                      当社は、個人情報の漏洩、滅失またはき損の防止その他の個人情報の安全管理が図られるよう、従業員に対する必要かつ適切な監督を行います。
                    </p>
                    <h5>１０．委託先の監督</h5>
                    <p>
                      当社は、個人情報の取扱いの全部又は一部を委託する場合は、委託先と機密保持を含む契約の締結、委託先において個人情報の安全管理が図られるよう、必要かつ適切な監督を行います。
                    </p>
                    <h5>１１．第三者提供の制限</h5>
                    <p>
                      当社は、あらかじめご本人の同意を得ないで、個人情報を第三者に提供しません。ただし、次に掲げる場合は除きます。
                    </p>
                    <p>
                      1.法令に基づく場合<br />
                      2.人の生命、身体または財産の保護のために必要がある場合であって、ご本人の同意を得ることが困難であるとき<br />
                      3.公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ご本人の同意を得ることが困難であるとき<br />
                      4.国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ご本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき<br />
                    </p>
                    <h5>１２．個人情報に関する事項の公表等</h5>
                    <p>
                      当社は、個人情報に関する次に掲げる事項について、ご本人の知り得る状態に置き、ご本人の求めに応じて遅滞なく回答します。
                    </p>
                    <p>
                      1.個人情報の利用目的（ただし、個人情報の保護に関する法律において、その義務がないと規定されるものは除きます。ご回答しない決定をした場合は、ご本人に対して遅滞なくその旨を通知します。<br />
                      2.個人情報に関するお問い合わせ窓口
                      「16．お問い合わせ」に提示します。
                    </p>
                    <h5>１３．個人情報の開示</h5>
                    <p>
                      当社は、ご本人から、個人情報の開示を求められたときは、ご本人に対し、遅滞なく開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。
                    </p>
                    <p>
                      1.ご本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合<br />
                      2.当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合<br />
                      3.他の法令に違反することとなる場合。なお、アクセスログなどの個人情報以外の情報については、原則として開示いたしません。<br />
                    </p>
                    <h5>１４. 個人情報の訂正等</h5>
                    <p>
                      当社は、ご本人から、個人情報が真実でないという理由によって、内容の訂正、追加または削除（以下「訂正等」といいます）を求められた場合には、他の法令の規定により特別の手続きが定められている場合を除き、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正等を行い、その旨ご本人に通知します。
                    </p>
                    <h5>１５．個人情報の利用停止等</h5>
                    <p>
                      当社は、ご本人から、ご本人の個人情報が、あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由、または偽りその他不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下「利用停止等」といいます）を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、個人情報の利用停止等を行い、その旨ご本人に通知します。ただし、個人情報の利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、ご本人の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じます。
                    </p>
                    <h5>１６．お問い合わせ</h5>
                    <p>
                      個人情報保護に関するお問い合わせは、下記窓口にお願い致します。<br />
                      イーコイン株式会社 問合せ窓口<br />
                      担当者 個人情報保護管理責任者<br />
                      E-Mail privacy@ecoin.co.jp
                    </p>
                  </div>
                </div>
                <div class="agree_box">
                  <input
                    type="checkbox"
                    id="agree_ckeckbox"
                    name="agreee"
                    value="1"
                  />同意する
                </div>
                <div class="actions_box">
                  <button id="comfirm_btn" class="btn btn_primary" type="submit">
                    <span>送信する</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "@/views/parts/Header";
import Footer from "@/views/parts/Footer";
import RakutenHeader from "@/views/parts/RakutenHeader";
import Api from "@/lib/api";
import { initScreen } from "../assets/javascripts/contact";
import $ from "jquery";

export default {
  name: "Contact",
  components: {
    Header,
    Footer,
    RakutenHeader,
  },
  data() {
    return {
      site_name: "",
    };
  },
  computed: {
    is_rakuten: function () {
      // 楽天か
      return this.site_name === "rakuten";
    },
    is_balloon: function () {
      // バルーンポップか
      return this.site_name === "phalanx_saison";
    },
    is_tori: function () {
      // トリポップか
      return !(this.is_balloon || this.is_rakuten);
    },
    game_name: function () {
      // ゲーム名
      return this.is_rakuten ? "進め！幻の海底神殿" : this.is_balloon ? "バルーンポップ" : "トリポップ";
    },
  },
  methods: {
    setUser: function (info) {
      this.site_name = info.data.site_name;
    },
    callGetApi: function () {
      Api.getUser(this, this.setUser);
    },
  },
  mounted: function () {
    this.callGetApi();
    initScreen();

    // FAQのアコーディオン
    $(".faq_list dd").hide();
    $(".faq_list dl").on("click", function(e){
      $("dd", this).slideToggle("fast");
      if ($(this).hasClass("open")) {
        $(this).removeClass("open");
      } else {
        $(this).addClass("open");
      }
    });
  },
};
</script>

<style lang="scss">
.contact_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.faq_list {
  dl {
    position: relative;
    margin: 0;
    padding: 28px 80px 28px 30px;
    cursor: pointer;
    border-bottom: 1px solid #000;
    @media screen and (max-width: 414px) {
      position: relative;
      padding: 15px 40px 15px 10px;
    }
    &:first-child {
      border-top: 1px solid #000;
    }
    &::before {
      position: absolute;
      top: 35px;
      right: 35px;
      display: block;
      width: 7px;
      height: 7px;
      margin: auto;
      content: '';
      transform: rotate(135deg);
      border-top: 2px solid #000;
      border-right: 2px solid #000;
      @media screen and (max-width: 414px) {
        top: 20px;
        right: 20px;
        width: 7px;
        height: 7px;
      }
    }
    .open {
      &::before {
        transform: rotate(-45deg);
      }
    }

    dt {
      position: relative;
      margin: 0;
      padding: 0 0 0 50px;
      font-weight: bold;
      font-size: 20px;
      @media screen and (max-width: 414px) {
        padding: 0 0 0 30px;
        font-size: 14px;
      }
      &::before {
        font-size: 22px;
        line-height: 1;
        position: absolute;
        top: 3px;
        left: 0;
        display: block;
        content: 'Q.';
        @media screen and (max-width: 414px) {
          font-size: 14px;
          top: 3px;
        }
      }
    }
    dd {
      position: relative;
      display: none;
      height: auto;
      margin: 20px 0 0;
      padding: 0 0 0 50px;
      @media screen and (max-width: 414px) {
        padding: 0 0 0 30px;
        font-size: 14px;
      }
      &::before {
        font-size: 22px;
        line-height: 1;
        position: absolute;
        top: 3px;
        left: 2px;
        display: block;
        content: 'A.';
        font-weight: bold;
        @media screen and (max-width: 414px) {
          font-size: 14px;
          top: 3px;
        }
      }
      p {
        margin: 30px 0 0;
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}
</style>
