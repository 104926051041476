export const API_URL = () => {
  if (`${window.location.origin}` == "https://toripop.placehub.co") {
    // 本番
    return "https://api.toripop.placehub.co/v1";
  } else if (
    `${window.location.origin}` == "https://stg-www.toripop.placehub.co"
  ) {
    // ステージング
    return "https://stg-api.toripop.placehub.co/v1";
  } else {
    return "http://localhost:3000/v1";
  }
};

export const isSP = () => {
  if (
    window.matchMedia &&
    window.matchMedia("(max-device-width: 414px)").matches
  ) {
    return true;
  } else {
    return false;
  }
};

export const isSPSmall = () => {
  if (
    window.matchMedia &&
    window.matchMedia("(max-device-width: 320px)").matches
  ) {
    return true;
  } else {
    return false;
  }
};
