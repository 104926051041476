<template>
  <div class="selectItem">
    <SelectItemHeader
      :medals="medals"
      :is_select_item_main="selected_item === null"
      @back="back"
    />
    <SelectItemMain
      :medals="medals"
      @selectItem="selectItem"
      v-if="selected_item === null"
    />
    <SelectItemMainConfirm
      :medals="medals"
      :selected_item="selected_item"
      @exchanged="exchanged"
      v-else
    />
  </div>
</template>

<script>
import SelectItemHeader from "@/views/parts/SelectItemHeader";
import SelectItemMain from "@/views/parts/SelectItemMain";
import SelectItemMainConfirm from "@/views/parts/SelectItemMainConfirm";

export default {
  name: "SelectItem",
  components: {
    SelectItemHeader,
    SelectItemMain,
    SelectItemMainConfirm,
  },
  props: {
    medals: Number,
  },
  data() {
    return {
      selected_item: null,
    };
  },
  computed: {},
  methods: {
    back: function () {
      if (this.selected_item === null) {
        this.$emit("closed");
      } else {
        this.selected_item = null;
      }
    },
    exchanged: function () {
      this.$emit("exchanged");
    },
    selectItem: function (item) {
      this.selected_item = item;
    },
  },
  watch: {},
  mounted: function () {
    // nothing
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/stylesheets/balloon/colors.scss";

.selectItem {
  display: flex;
  flex-direction: column;
  background: url("~@/assets/images/balloon/bg_select-item.svg") center center /
    cover no-repeat;
  font-weight: 900;
}
</style>
