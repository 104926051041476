<template>
  <div class="selectItemConfirm">
    <div class="selectItemConfirm_main">
      <div class="selectItemConfirm_left">
        <img :src="selected_item.image_src" />
      </div>
      <div class="selectItemConfirm_right">
        <div class="selectItemConfirm_name">
          <div class="selectItemConfirm_name-name">
            {{ selected_item.name }}
          </div>
          <div class="selectItemConfirm_name-point">
            {{ selected_item.medals }}メダル
          </div>
        </div>
        <div class="selectItemConfirm_description">
          {{ selected_item.desc }}
        </div>
        <div class="selectItemConfirm_input">
          <div class="selectItemConfirm_input-title">交換数</div>
          <div class="selectItemConfirm_input-number">
            {{ quantity }}
          </div>
          <button class="selectItemConfirm_input-plus" @click="increment">
            <img src="~@/assets/images/balloon/btn_plus.svg" />
          </button>
          <button
            class="selectItemConfirm_input-minus"
            :disabled="!canDecrement"
            @click="decrement"
          >
            <img src="~@/assets/images/balloon/btn_minus.svg" />
          </button>
        </div>
        <hr class="selectItemConfirm_hr" />
        <div class="selectItemConfirm_sum">
          合計
          <span class="selectItemConfirm_sum-number">{{
            selected_item.medals * quantity
          }}</span>
          メダル
        </div>
      </div>
    </div>
    <div class="selectItemConfirm_actions">
      <button
        class="selectItemConfirm_exchangeButton"
        :disabled="!canExhange"
        @click="exchange"
      >
        <img src="~@/assets/images/balloon/btn_exchange.svg" />
      </button>
    </div>
  </div>
</template>

<script>
import Api from "@/lib/api";

export default {
  name: "SelectItemMainConfirm",
  components: {},
  props: {
    medals: Number,
    selected_item: {
      point_type: Number,
      name: String,
      desc: String,
      image_src: Object,
      medals: Number,
    },
  },
  data() {
    return {
      quantity: 1,
    };
  },
  computed: {
    canExhange: function () {
      return this.selected_item.medals * this.quantity <= this.medals;
    },
    canDecrement: function () {
      return this.quantity > 1;
    },
  },
  methods: {
    increment: function () {
      this.quantity++;
    },
    decrement: function () {
      if (this.canDecrement) {
        this.quantity--;
      }
    },
    callbackExchange: function () {
      this.$emit("exchanged");
    },
    exchange: function () {
      if (this.canExhange) {
        Api.exhange(
          this,
          this.selected_item.point_type,
          this.quantity,
          this.callbackExchange
        );
      }
    },
  },
  watch: {},
  mounted: function () {
    // nothing
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/stylesheets/balloon/colors.scss";

.selectItemConfirm {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &_main {
    display: flex;
    justify-content: space-around;
    padding: 36px 24px 24px 24px;
    @media screen and (max-width: 414px) {
      flex-direction: column;
      align-items: center;
      padding: 24px;
    }
    @media screen and (max-width: 320px) {
    }
  }
  &_left {
    margin: 0 36px 0 0;
    img {
      width: 216px;
      height: auto;
      @media screen and (max-width: 414px) {
        width: 162px;
      }
      @media screen and (max-width: 320px) {
      }
    }
    @media screen and (max-width: 414px) {
      margin-right: 0;
    }
    @media screen and (max-width: 320px) {
    }
  }
  &_right {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &_name {
    display: flex;
    align-items: center;
    margin: 0 0 24px 0;
    &-name {
      color: $text-color-reverse;
      font-size: 1.5rem;
      text-shadow: 2px 2px 1px #003366, -2px 2px 1px #003366,
        2px -2px 1px #003366, -2px -2px 1px #003366, 2px 0px 1px #003366,
        0px 2px 1px #003366, -2px 0px 1px #003366, 0px -2px 1px #003366;
    }
    &-point {
      margin: 0 0 0 24px;
      color: $accent-color;
      font-size: 1rem;
      text-shadow: 2px 2px 1px #003366, -2px 2px 1px #003366,
        2px -2px 1px #003366, -2px -2px 1px #003366, 2px 0px 1px #003366,
        0px 2px 1px #003366, -2px 0px 1px #003366, 0px -2px 1px #003366;
      letter-spacing: 0.1rem;
    }
  }
  &_description {
    margin: 0 0 24px 0;
    color: $text-color-reverse;
  }
  &_input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-title {
      width: 80px;
      color: $text-color-reverse;
      font-size: 1.5rem;
      text-shadow: 2px 2px 1px #003366, -2px 2px 1px #003366,
        2px -2px 1px #003366, -2px -2px 1px #003366, 2px 0px 1px #003366,
        0px 2px 1px #003366, -2px 0px 1px #003366, 0px -2px 1px #003366;
    }
    &-number {
      flex-grow: 1;
      height: 50px;
      margin: 0 auto 0 24px;
      padding: 8px 24px;
      border-radius: 25px;
      color: $text-color;
      background-color: $base-color;
      text-align: right;
      font-size: 2rem;
      line-height: 2rem;
    }
    &-plus {
      margin: 0 4px 0 24px;
      padding: 0;
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none;
      appearance: none;
      img {
        width: 48px;
        height: 48px;
      }
      &:disabled {
        cursor: initial;
        opacity: 0.5;
      }
    }
    &-minus {
      margin: 0 0 0 4px;
      padding: 0;
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none;
      appearance: none;
      img {
        width: 48px;
        height: 48px;
      }
      &:disabled {
        cursor: initial;
        opacity: 0.5;
      }
    }
  }
  &_hr {
    width: 100%;
    border-top: 1px solid $base-color;
  }
  &_sum {
    color: $text-color-reverse;
    font-size: 1.5rem;
    text-align: right;
    text-shadow: 2px 2px 1px #003366, -2px 2px 1px #003366, 2px -2px 1px #003366,
      -2px -2px 1px #003366, 2px 0px 1px #003366, 0px 2px 1px #003366,
      -2px 0px 1px #003366, 0px -2px 1px #003366;
    &-number {
      color: $accent-color;
      font-size: 2.5rem;
    }
  }
  &_actions {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 0 0 12px 0;
    @media screen and (max-width: 414px) {
      margin-bottom: 24px;
    }
    @media screen and (max-width: 320px) {
    }
  }
  &_exchangeButton {
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    appearance: none;
    img {
      width: 300px;
    }
    &:disabled {
      cursor: initial;
      opacity: 0.5;
    }
  }
}
</style>
